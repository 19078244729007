// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-brands-js": () => import("./../../../src/templates/Brands.js" /* webpackChunkName: "component---src-templates-brands-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-nicotine-pouches-js": () => import("./../../../src/templates/NicotinePouches.js" /* webpackChunkName: "component---src-templates-nicotine-pouches-js" */),
  "component---src-templates-retailers-js": () => import("./../../../src/templates/Retailers.js" /* webpackChunkName: "component---src-templates-retailers-js" */)
}

