import React from 'react';
import '@reach/skip-nav/styles.css';
import 'focus-visible';

import CookieNotice from './src/components/CookieNotice/CookieNotice';

const wrapPageElement = ({ element, props }) => {
	return (
		<>
			{element}
			<CookieNotice props={props} />
		</>
	);
};

export { wrapPageElement };
