import React, { useState } from 'react';
import Cookies from 'universal-cookie';

import styles from './CookieNotice.module.scss';

const cookies = new Cookies();
const COOKIE_CONSENT_NAME = 'foxcap-cookie-consent';

const CookieNotice = () => {
	const [isCookieConsentApproved, setIsCookieConsentApproved] = useState(
		cookies.get(COOKIE_CONSENT_NAME)
	);

	const handleClick = () => {
		cookies.set(COOKIE_CONSENT_NAME, true, {
			path: '/',
			expires: new Date(
				new Date().setFullYear(new Date().getFullYear() + 1)
			),
		});
		setIsCookieConsentApproved(true);
	};

	const handleCancel = () => {
		setIsCookieConsentApproved(true);
	};

	if (isCookieConsentApproved) {
		return null;
	}

	return (
		<div className={styles.cookie}>
			<p>
				This website uses cookies to improve the user experience.
				<button className={styles.ok} onClick={handleClick}>
					I accept
				</button>
			</p>
			<button className={styles.x} onClick={handleCancel}>
				X
			</button>
		</div>
	);
};

export default CookieNotice;
