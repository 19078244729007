module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Foxtrot Uniform Capital","short_name":"foxtrotuniformcapital.com","start_url":"/","background_color":"#ffffff","theme_color":"#dac2b2","display":"standalone","icon":"src/assets/images/favicon.png","icon_options":{"purpose":"any maskable"},"icons":[{"src":"/favicons/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b512ae8b3a61412978836aa9744276cd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-178051072-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
